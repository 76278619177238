<template>
  <div class="address"  v-if="showFlag">
    <!-- <van-nav-bar title="地址管理" left-arrow @click-left="onClickLeft" /> -->
    <van-address-list
      v-model="chosenAddressId"
      :list="list"
      default-tag-text="默认"
      @add="onAdd"
      @edit="onEdit"
      @select="onSelect"
    />
  </div>
  <div class="address" v-if="!showFlag">
    <van-address-list
      v-model="chosenAddressId"
      :list="list"
      :switchable="false"
      default-tag-text="默认"
      @add="onAdd"
      @edit="onEdit"
    />
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { getAddressList } from '@/service/user'
  import { useRouter } from 'vue-router'
  export default {
    setup() {
      const router = useRouter();
      const list = ref([]);
      const storage= localStorage.getItem("token-hyxt");
      const token=JSON.parse(storage);
      const disabledList = ref([]);
      const chosenAddressId = ref('');
      const onClickLeft = () => history.back();
      const showFlag=ref(false);
      let goods=router.currentRoute.value.query.goods;
      if(goods){
        showFlag.value=true;
      }
      const onAdd = () => {
        router.push({path: '/addressEdit', query: {goods:goods}});
      }
      const onEdit = (item) => {
        router.push({path: '/addressEdit', query: {addrId: item.id,goods:goods}});
      }
      const onSelect = (item) => {
        router.push({path: '/pay/book', query: {goods:goods,conName: item.name,conMobile:item.tel,address:item.address}});
      }
      const loadData = async () => {
        const res = await getAddressList(token.userId);
        if(res.data.data) {
          for(var item of res.data.data){
            list.value.push({id: item.id, name: item.conName, tel: item.conMobile, address: item.area+" "+item.detail, isDefault: item.checked})
          }
        }
      }
      onMounted(() => {
        loadData();
        chosenAddressId.value = '1';
      })

      return {
        list,
        disabledList,
        chosenAddressId,
        onClickLeft,
        onAdd,
        onEdit,
        onSelect,
        showFlag,
      }
    }
  }
</script>

<style lang="scss">
  .address {
    --van-nav-bar-icon-color: #cdcdcd;
    bottom: inherit;
  }
  .van-address-list__bottom {
    background-color: inherit;
  }
</style>
